
import {
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  toRef,
  watch
} from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import usePagination from '@/plugins/usePagination'
import { modalInstance, notifyInstance } from '@/type'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

interface salesRecord {
  productsNo?: string
  productsStandardNo?: string
  productsName?: string
  productsStandardMain?: string
  productsId?: string
  productsStandardId?: string
  price: number
  quantity: number
}

export default defineComponent({
  props: ['propRange'],
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)

    const exportType = ref('ExchangeSales')

    let pagination: {
      next: () => any
      pre: () => any
      go: (goPage: number) => any
      getData: () => any
    } | null = null

    const paginationData = reactive({
      page: 1,
      pageSize: 20,
      lastPage: 1,
      total: 0,
      salesRecords: ref(new Array<salesRecord>())
    })

    watch(
      () => props.propRange,
      async (range: any) => {
        init()
      },
      {
        deep: true
      }
    )

    async function init() {
      paginationData.page = 1
      const paginationSetting = reactive({
        page: toRef(paginationData, 'page'),
        pageSize: toRef(paginationData, 'pageSize'),
        api: `/saleReport/${storeId.value}/exchangeStatistics`,
        payload: {
          startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
          endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
        }
      })
      usePagination(paginationSetting).then((res) => {
        pagination = res
        getData()
      })
    }

    function formatNumber(number: any) {
      return number === undefined ? 0 : Number(number).toLocaleString('en-US')
    }

    function nextPage() {
      const fetchData = pagination?.next()
      fetchData.then(() => {
        getData()
      })
    }

    function prePage() {
      const fetchData = pagination?.pre()
      fetchData.then(() => {
        getData()
      })
    }

    function goPage(page: number) {
      const fetchData = pagination?.go(page)
      fetchData.then(() => {
        getData()
      })
    }

    function getData() {
      const data = pagination?.getData()
      paginationData.page = data.currentPage
      paginationData.lastPage = data.lastPage
      paginationData.pageSize = data.pageSize
      paginationData.total = data.total
      paginationData.salesRecords = data.items
    }

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            startTime: moment(props.propRange.start).format('YYYY-MM-DDTHH:mm'),
            endTime: moment(props.propRange.end).format('YYYY-MM-DDTHH:mm')
          },
          modal,
          notify,
          router
        )
      }
    }

    function getProductStandardName(salesRecord: any) {
      if (salesRecord.standardNameMain.length > 0) {
        if (salesRecord.standardNameSub.length > 0) {
          return `(${salesRecord.standardNameMain}-${salesRecord.standardNameSub})`
        }
        return `(${salesRecord.standardNameMain})`
      }
      return ''
    }

    init()

    return {
      formatNumber,
      pagination,
      paginationData,
      nextPage,
      prePage,
      goPage,
      exportExcelClick,
      getProductStandardName
    }
  }
})
